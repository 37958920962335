import { ChakraProvider } from '@chakra-ui/react';
import { formatISO } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz'
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { Fragment, createContext } from 'react';
import { Layout } from '../container/Layout';
import { RequestMetada, fetchAPI } from '../lib/api';
import { getStrapiMedia } from '../lib/media';
import '../styles/globals.css';
import theme from '../theme';
import {
  ArticleCategory,
  HallendienstPlan,

  LayoutConfig,

  PageContentEntry,
  StrapiAlert,
  StrapiEvent,
  StrapiGlobal,
  StrapiGlobalAttributes,
  StrapiImage,
  TrainingsZeiten,
} from '../types';

interface StrapiBackground {
  attributes: {
    image: {
      data: StrapiImage
    }
  }
}

interface DefautProps {
  global: StrapiGlobal;
  categories: ArticleCategory[];
  events: { data: StrapiEvent[], meta: RequestMetada }
  trainings: { data: HallendienstPlan, meta: RequestMetada },
  normalTrainings: { data: TrainingsZeiten, meta: RequestMetada },
  background: StrapiBackground,
  meta?: {
    pageContent: PageContentEntry[]
  },
  alerts: StrapiAlert,
  layout?: LayoutConfig['layout']
}

export const GlobalContext = createContext<StrapiGlobalAttributes>(
  {} as StrapiGlobalAttributes
);

const resolveLayout = (conf?: LayoutConfig['layout']): LayoutConfig['layout'] => {

  return {
    containerWidth: conf?.containerWidth || 'container.md',
    showEvents: typeof conf !== 'undefined' ? conf!.showEvents : true
  }
}

function MyApp({ Component, pageProps }: AppProps<DefautProps>) {
  const global = pageProps.global as StrapiGlobal;
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Head>
        {getStrapiMedia(global.attributes.favicon.data).map(url => <link rel="shortcut icon" href={url} />).unwrapOr(<Fragment />)}
      </Head>
      <GlobalContext.Provider value={global.attributes}>
        <Layout
          categories={pageProps.categories}
          events={pageProps.events}
          trainings={pageProps.trainings}
          normalTrainings={pageProps.normalTrainings}
          background={pageProps.background}
          pageContent={pageProps.meta?.pageContent}
          alerts={pageProps.alerts}
          layout={resolveLayout(pageProps.layout)}
        >
          <Component {...pageProps} />
        </Layout>
      </GlobalContext.Provider>
    </ChakraProvider>
  );
}

MyApp.getInitialProps = async (ctx: AppContext) => {

  const appProps = await App.getInitialProps(ctx);
  const [
    background,
    globalRes,
    categories,
    eventsRes,
    trainingsRes,
    normalTrainings,
    alertsRes,
  ] = await Promise.all([
    fetchAPI<StrapiBackground>('/background', {
      populate: {
        image: {
          populate: '*',
        },
      },
    }),
    fetchAPI('/global', {
      populate: '*',
    }),
    fetchAPI<ArticleCategory[]>('/categories', {
      populate: '*',
      // filters: {
      //   einordnung: {
      //     id: {
      //       '$null': true
      //     }
      //   }
      // },
      sort: 'rank:asc',
    }),
    fetchAPI<StrapiEvent[]>('/events', {
      populate: '*',
      'pagination[page]': 1,
      'pagination[pageSize]': 5,
      sort: 'datum',
      filters: {
        datum: {
          $gt: format(new Date(), "yyyy-MM-dd"),
        },
      },
    }),
    fetchAPI<HallendienstPlan>('/schluesseldiensts', {
      'sort[0]': 'date.date',
      'sort[1]': 'date.from',
      'pagination[page]': 1,
      'pagination[pageSize]': 5,
      populate: '*',
      filters: {
        date: {
          date: {
            $gt: formatISO(new Date())
          }
        }
      }
    }),
    fetchAPI<TrainingsZeiten>('/trainingszeiten', {
      populate: {
        zeiten: {
          populate: '*',
        },
        blocks: {
          populate: '*'
        }
      }
    }),
    fetchAPI<StrapiAlert>('/alert', {
      populate: '*',
    }),
  ]);

  return {
    ...appProps,
    pageProps: {
      background: background.data,
      global: globalRes.data,
      categories: categories.data,
      events: eventsRes,
      alerts: alertsRes,
      trainings: trainingsRes,
      normalTrainings
    },
  };
};

export default MyApp;

import {
  formatISO,
  isValid,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { de } from 'date-fns/locale';
import { Weekday } from '../types';

export enum Patterns {
  FULL = "cccc d MMM YYY H:mm 'Uhr'",
  TIME_ONLY = "H:mm 'Uhr'",
  TIME_ONLY_NO_UNIT = 'H:mm',
  TIMELESS = 'd MMM YYY',
  WEEKDAY = 'EEEE d MMM',
  _STRAPI_PATTERN = 'yyyy-MM-dd',
}

const normalice = (dateOrString: Date | string): Date => {
  if (typeof dateOrString == 'string') {
    if (dateOrString.length <= 10) {
      return new Date(dateOrString.replace(/\./g, '-'));
    }
  }

  return new Date(dateOrString);
};

function formatDate(
  dateOrString: string | Date,
  pattern: Patterns = Patterns.FULL
) {
  return format(normalice(dateOrString), pattern, {
    locale: de,
  });
}

const formatToUTC = (dateOrString: string | Date) => {
  const date = normalice(dateOrString);
  return format(utcToZonedTime(date, 'UTC'), 'yyyy-MM-dd kk:mm:ss xxx', {
    timeZone: 'UTC',
  });
};

const toISO = (dateOrString: string | Date) =>
  formatISO(normalice(dateOrString)).replace('+01:00', 'Z');

const nextWeekday = (day: Weekday, date: Date) => {
  return {
    [Weekday.Montag]: nextMonday,
    [Weekday.Dienstag]: nextTuesday,
    [Weekday.Mittwoch]: nextWednesday,
    [Weekday.Donnerstag]: nextThursday,
    [Weekday.Freitag]: nextFriday,
    [Weekday.Samstag]: nextSaturday,
    [Weekday.Sonntag]: nextSunday,
  }[day](date);
};

const timeToDate = (time: string): Date => {
  const [hour, minute] = time.split(':');
  const date = new Date('2000-01-01');
  date.setHours(Number.parseInt(hour));
  date.setMinutes(Number.parseInt(minute));
  return date;
};

export { formatDate, formatToUTC, toISO, nextWeekday, normalice, timeToDate };
